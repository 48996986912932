import { setQueue, setBackup } from '../features/queueSlice';
import { fetchWithDefaults } from '../config/fetchDefault';

const updateLeadInfo = async (queue, column, data, leadId, dispatch, type)=>{
    const updatedArray = queue.map(item => {
        // Check if the current item's ids matches the targetTagId
        if (item.lead_id === leadId) {
            // Return a new object with the updated tag_ids
            return {
                ...item,
                [column]: data
            };
        } else {
            // If it doesn't match, return the item as is
            return item;
        }
        
    });
    if(type==='backup'){
        dispatch(setBackup(updatedArray));
    }
    else{
        dispatch(setQueue(updatedArray));
    }
    return true;
}
const viewFlowData= async (id, setFlowData, leadId)=>{
    try {
        const response = await fetchWithDefaults(`/flowInfo?lead=${leadId}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setFlowData(jsonData);

    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
function removeKeys(obj, keysToRemove) {
    // Loop through the keys in the array
    keysToRemove.forEach((key) => {
      // Check if the key exists in the object
      if (obj.hasOwnProperty(key)) {
        // If it exists, delete the key
        delete obj[key];
      }
    });
    return obj;
}
const updateLeadData = async (field, value, leadId, agentId) => {
    try {        
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                agent: agentId,
                lead:leadId,
                column:field,
                value:value
            }) 
        };
        const response = await fetchWithDefaults('/updateLead', requestOptions);
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
       
        const jsonData = await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
export { updateLeadInfo, viewFlowData, updateLeadData, removeKeys};