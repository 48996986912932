import React, { useState, useEffect, useRef } from 'react';
import { fetchWithDefaults } from '../../../../config/fetchDefault';
import { useSelector } from 'react-redux';
import Icon from '../../../../assets/icons/icon';
import { removeKeys } from '../../../../utilities/leadFunctions';


function AdditionalInfo({leadData, handleLeadData}) {
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const [isOpen, setIsOpen] = useState(false);
    const [innerWrapperHeight, setInnerWrapperHeight] = useState(0);
    const [customFields, setCustomFields] = useState({});
    const [editingField, setEditingField] = useState(null);
    const inputRef = useRef(null);
    const innerWrapperRef = useRef(null);
    const keysToRemove = ["ID","Switchboard", "pushed_to_sb", "SB_Contact_ID", "Tags","PushedToOptDate"];
    const openUp = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (innerWrapperRef.current) {
            setInnerWrapperHeight(innerWrapperRef.current.clientHeight);
        }
    }, [isOpen]);

    useEffect(() => {
        if(leadData[0]['custom_fields'] !== ' N;' || leadData[0]['custom_fields'] !== '{}' || leadData[0]['custom_fields'] !== 'N; ' || leadData[0]['custom_fields']){
            const newObj=removeKeys(JSON.parse(leadData[0]['custom_fields']), keysToRemove)
            setCustomFields(newObj);
        }
    }, [leadData]);
    const handleFieldChange = (key, newValue) => {
        setCustomFields(prevFields => ({
            ...prevFields,
            [key]: newValue
        }));
        
    };
    const updateLeadData = async (field, value) => {
        try {        
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentId,
                    lead:leadData[0].lead_id,
                    column:field,
                    value:value
                }) 
            };
          
            const response = await fetchWithDefaults('/updateLead', requestOptions);
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
           
            const jsonData = await response.json();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        if (editingField && inputRef.current) {
          inputRef.current.focus();
        }
    }, [editingField]);

    return (
        <div className={`quilityNestedBlockWrapper ${isOpen ? '' : ''}`} >
            <div className="quilityNestedBlockHeader" onClick={openUp}>
                <div className="quilityParentBlockTitle">Lead Information</div>
                <span className={`quilityBlockIcon ${isOpen ? 'rotate':''}`} style={{marginTop: '-5px', transition:'all .5s'}}><span className="fontello"><Icon name={'q-up'} size={20} className="closeIcon" adjust="fontello" /></span></span>
            </div>
            <div className="quilityNestedBlockContent" style={{ height: isOpen ? innerWrapperHeight + 25 : '0px' }}>
                <div className="innerWrapper" ref={innerWrapperRef}>
                    {Object.entries(customFields).map(([key, value]) => (
                        key !== 'Status' && key !== 'Type of Contact' && (
                            <div className="leadCardBlock doubleLine" key={`additionalInfoBlock${key}`}>
                                <div key={key} className="leadInfoLine" >
                                    <div className="dataFieldTitle">{key.replace('_',' ')}</div>
                                    <span className="leadData">
                                        <input
                                            type="text"
                                            className="qualifierFieldInput"
                                            value={value}
                                            onChange={(e) => handleFieldChange(key, e.target.value)}
                                            onBlur={() => {
                                                updateLeadData('custom_fields', JSON.stringify(customFields));
                                                setEditingField(null);
                                            }}
                                            ref={inputRef}
                                        />
                                    </span>
                                </div>
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AdditionalInfo;

