import React, {useState, useRef, useEffect} from 'react';
import { fetchWithDefaults } from '../../../../config/fetchDefault';
import { useSelector, useDispatch } from 'react-redux';
import { SuccessAlert } from '../../../successAlert';
import { incrementQueue } from '../../../../features/queueSlice';


function DispositionBlock({type, setDisposition, handleLeadData,setCurrentDial, theCall, from, updateStatusName, handlCallAccepted, click, queuePos, leadId, tags}) {    
    const dispatch= useDispatch();
    const agentInfo=useSelector((state) => state.agent.userData);
    const textingNumbers=useSelector((state) => state.agent.textingNumbers);
    const statuses=useSelector((state) => state.status.status);
    const [isOpen, setIsOpen] = useState(false);
    const [innerWrapperHeight, setInnerWrapperHeight] = useState(0);
    const innerWrapperRef = useRef(null);
    const [successTrigger, setSuccessTrigger] = useState(false);
    const formatTags = (tagsArray) => {
        return tagsArray
            .map(tag => `${tag.id}*${tag.name}`) // Format each object as "id*name"
            .join(','); // Join all formatted strings with a comma
    };

    useEffect(() => {
        if (innerWrapperRef.current) {
            setInnerWrapperHeight(innerWrapperRef.current.clientHeight);
        }
    }, [isOpen]);

    useEffect(() => {
        if(from==='session'){
            setIsOpen(true);
        }
    }, []);
   
    const statusLead= async (status)=>{
        setDisposition(leadId)
        setCurrentDial(100);
        theCall.disconnect();
        handlCallAccepted(false);
        const statusId=status.id;
        const workflowId=status.workflow;
        const reminderId=status.reminder;
        let callTag
        if(tags){
            callTag = tags.split(',').map(pair => {
                const [id, name] = pair.split('*');
                return { id, name };
            });
        }
        else{
            callTag=null;
        }
        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    agent: agentInfo.agentId,
                    leadId:leadId,
                    statusId:statusId,
                    workflowId:workflowId,
                    reminderId:reminderId,
                    status:status,
                    teamId:agentInfo.teamId,
                    from:from,
                    tags:callTag,
                    textingNumbers:textingNumbers
                }),
            };
            
            const response = await fetchWithDefaults('/lead/status', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setSuccessTrigger(true);
            const jsonData = await response.json();

            incrementQueue(queuePos);
            handlCallAccepted(false);

            const newTags = formatTags(jsonData.newTags);
            handleLeadData('tag_ids', newTags);
            dispatch(incrementQueue(leadId));
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const statusLead2= async (status)=>{
        
        //splitting the tags and find the the ones that are associated with dial amounts
        let callTag
        if(tags){
            callTag = tags.split(',').map(pair => {
                const [id, name] = pair.split('*');
                return { id, name };
            });
        }
        else{
            callTag=null;
        }
        //handleLeadData('called', true);
        handlCallAccepted(false);
        
        const statusId=status.id;
        const workflowId=status.workflow;
        const reminderId=status.reminder;

        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    agent: agentInfo.agentId,
                    leadId:leadId,
                    statusId:statusId,
                    workflowId:workflowId,
                    reminderId:reminderId,
                    status:status,
                    teamId:agentInfo.teamId,
                    from:from,
                    tags:callTag,
                    textingNumbers:textingNumbers
                }),
            };
            //setSuccessTrigger(true);
            const response = await fetchWithDefaults('/lead/status', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setSuccessTrigger(true);
            
            //incrementQueue(queuePos);
            const newTags = formatTags(jsonData.newTags);
            handleLeadData('tag_ids', newTags);
            dispatch(incrementQueue(leadId));
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const statusLead3= async (status)=>{
        let callTag 
        if(tags){
            callTag = tags.split(',').map(pair => {
                const [id, name] = pair.split('*');
                return { id, name };
            });
        }
        else{
            callTag=null;
        }

        const statusId=status.id;
        const workflowId=status.workflow;
        const reminderId=status.reminder;
        updateStatusName(status.name);
        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    agent: agentInfo.agentId,
                    leadId:leadId,
                    statusId:statusId,
                    workflowId:workflowId,
                    reminderId:reminderId,
                    status:status,
                    teamId:agentInfo.teamId,
                    from:'',
                    tags:callTag,
                    textingNumbers:textingNumbers
                }),
            };
            
            const response = await fetchWithDefaults('/lead/status', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

            const newTags = formatTags(jsonData.newTags);
            handleLeadData('tag_ids', newTags);
            
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    return (
        <>
            <SuccessAlert message={'Status Changed'} setSuccessTrigger={setSuccessTrigger} successTrigger={successTrigger}/>
            <div className="statusButtonBlock" >
                {statuses.map((status, index) => (
                    type==='client' && status.section==='client' ? (
                        <div key={`innerStatus${index}`} className="statusItem solidQuilityActiveButton" onClick={() => click ? statusLead2(status) : from==='dash' ? statusLead3(status) : statusLead(status)}>{status.name}</div>
                    ) : status.section ==='active' && type !=='client' && (
                        <div key={`innerStatus${index}`} className="statusItem solidQuilityActiveButton" onClick={() => click ? statusLead2(status) : from==='dash' ? statusLead3(status) : statusLead(status)}>{status.name}</div>
                    )
                ))}
            </div>
        </>
    );
    
}

export default DispositionBlock;
