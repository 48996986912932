import React, { useState, useEffect } from 'react';
import '../../css/queue.css';
import RightQueueHeader from './elements/rightQueueHeader';
import QueueScripts from './elements/scripts';
import LeadCardData from './elements/leadCard';
import QueueConvo from './elements/conversation';
import EditEvent from './elements/sections/editEvent';
import AddEvent from './elements/sections/addEvent';
import QueueNotes from './elements/notes';
import LeadsQueue from '../leads/leadsQueue.js';
import { fetchWithDefaults } from '../../config/fetchDefault';
import areaCodes from '../areaCodes';
import DialModule from './dialModule.js';
import { useSelector, useDispatch } from 'react-redux';
import { setQueue, addCurrentLead } from '../../features/queueSlice';
import Recommendation from './elements/sections/recommendation.js';
import Email from '../email/email.js';
import Icon from '../../assets/icons/icon.js';
import Scripting from '../scripting/scripting.js';
import Qualifier from '../qualifier/qualifier.js';
import { setStartSession } from '../../features/callSlice.js';
import { runVM } from '../../utilities/virtualMentor.js';


function QueueModule({setSessionStarted, sessionStarted, setLoading, newAppt, setNewAppt, setCalledQueue, setHoldingQueue, setLeadCard, expandSession, textingNumbers,handleUpdateQueueLead, openQueue, holdingQueue, expanded, open, setMyQueue, workParams, device, formatDate}) {
    const dispatch = useDispatch();
    const [updateQueuePos, setUpdateQueuePos] = useState(false);
    const [possibleConditions, setPossibleConditions] = useState([]);
    const [update, setUpdate] = useState(false);
    const [qualifier, setQualifier] = useState(false);
    const [scripting, setScripting] = useState(false);
    const [scriptType, setScriptType] = useState('dynamic');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [term, setTerm] = useState('15');
    const [results, setResults] = useState(false);
    const [timestamp, setTimestamp] = useState(false);
    const [profile, setProfile] = useState(false);
    const [faceAmount, setFaceAmount] = useState('100000');
    const queue=useSelector((state) => state.queue.queue);
    const resetQueuePositions = () => {
        const updatedData = queue.map((item, index) => {
            return {
                ...item,
                queuePos: index,
            };
        });
        setCurrentLead(updatedData[0]);
        setCurrentScript(updatedData[0].script);
        setCurrentLeadId(updatedData[0].lead_id);
        setUpdateQueuePos(true);
        dispatch(setQueue(updatedData));
    };
    const handleLeadData=(field, value)=>{
       setCurrentLead((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    }
    const agentInfo=useSelector((state) => state.agent.userData);
    const numberInfo=useSelector((state) => state.agent.phoneNumbers);
    const [selected, setSelected] = useState(1);
    const [smartQueue, setSmartQueue] = useState('smart');
    const [calendar, setCalendar] = useState(agentInfo.defaultCalendar);
    const [dialAmount, setDialAmount]=useState(false);
    const [currentScript, setCurrentScript]=useState(null);
    const [amountSelector, setAmountSelector]=useState(false);
    const [currentLead, setCurrentLead]=useState([]);
    const [currentLeadId, setCurrentLeadId]=useState(false);
    const [dialGroupDone, setDialGroupDone]=useState(false);
    const [conversations, setConversations] = useState([]);
    const [callAccepted, setCallAccepted]=useState(false);
    const [newObjection, setNewObjection]=useState(false);
    
    const agentId = agentInfo.agentId;
    const teamId = agentInfo.teamId;
    const [call, setCall] = useState(null);
    const [event, setEvent] = useState(false);
    const [newEvent, setNewEvent] = useState(false);
    const [date, setDate] = useState(false);
    const [time, setTime] = useState(false);

    const defaultNumber = numberInfo[0] ? numberInfo[0].number : null;
    const [selectedNumber, setSelectedNumber] = useState(defaultNumber);
    const [callState, setCallState] = useState('Disconnected');

     
    const setAppointment= async (event,video)=>{

        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    agent: agentId,
                    team: teamId,
                    leadId:currentLeadId,
                    event:event,
                    video:video
                }),
            };
            const response = await fetchWithDefaults('/google/create/event', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setNewAppt(jsonData.event)
            setNewEvent(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

        
    function searchAreaCode(areaCode) {
        const code = areaCode.toString().substring(0, 3);
        const foundState = areaCodes.find(state => {
        return Object.values(state).flat().includes(parseInt(code));
        });

        // If the area code is found, return the array of area codes
        // Otherwise, return an empty array
        return foundState ? Object.values(foundState)[0] : [];
    }
    function getFirstNonNullValue(...values) {
        for (const value of values) {
            if (value !== null && value !== undefined) {
                return value;
            }
        }
        return null; // Return null if all values are null or undefined
    }
    useEffect(() => {
        if(currentLead ){
            //gatherConversation();
            if(currentLead.call_from !== null && currentLead.call_from !==undefined){
                setSelectedNumber(currentLead.call_from);
            }
            else{
                if(currentLead.borrower_cell && numberInfo){
                    const clientNumberToSearch=getFirstNonNullValue(currentLead.borrower_cell,currentLead.borrower_home,currentLead.borrower_work);
                    if(clientNumberToSearch !== null){
                        const callingState=searchAreaCode(clientNumberToSearch)
                        let callFrom;
                        for (let i = 0; i < numberInfo.length; i++) {
                            const number = numberInfo[i].number;
                            const areaCode = parseInt(number.substring(2, 5));
                            if (callingState && callingState.includes(areaCode)) {
                                callFrom = number;
                                setSelectedNumber(callFrom);
                                break; 
                            }
                        }
                        
                    }
                }
            }
        }
  
    }, [currentLeadId]);
    const isStateEmpty = (state) => {
        return !Object.values(state).some(value => value !== null);
    };
    useEffect(() => {
        if(!isStateEmpty(profile)){
            runVM(profile, setResults, currentLead.lead_id);
        }
    }, [profile]);
    const incrementQueue=(currentIndex)=>{
        if (currentIndex >= 0 && currentIndex < queue.length - 1) {
            // Get the next lead based on the currentIndex
            const nextLead = queue[currentIndex + 1];
         
            const updatedQueue = queue.map(item => {
                if (item.lead_id === currentLead.lead_id) {
                    return { ...item, called: true };
                }
                return item;
            });
            setHoldingQueue(updatedQueue);
            dispatch(setQueue(updatedQueue));
            setCurrentLead(nextLead);
            setCurrentLeadId(nextLead['lead_id']);
        } else {
            dispatch(setQueue(false));
            //setMyQueue(false);
            setCalledQueue([]);
        }
    }
    const handleNewObjection=(objection)=>{
        setNewObjection(objection);
    }
  
    
    
    const selectTab=(index)=>{
        setSelected(index)
    }
    
    
    const openDialAmount=()=>{
        setAmountSelector(!amountSelector);
    }
    
    const closeObjection=()=>{
        setNewObjection(false);
    }

    useEffect(() => {
        if(queue){
            dispatch(addCurrentLead(queue[0]));
            setCurrentLead(queue[0]);
            //setCurrentScript(queue[0].script);
            setCurrentLeadId(queue[0].lead_id);
        }
    },[queue])
    useEffect(() => {
        if(expanded){
            resetQueuePositions();
        }
    },[expanded])
    let outgoingCall='';
    const makeDial= async (toNumber, leadName, fromNum)=>{
        let dialFrom;
        if(fromNum){
            dialFrom=fromNum;
        }
        else{
            dialFrom=selectedNumber;
        }
        //var param = { "recordSide":$("#recordSide").val(),"recordCalls":$("#recordCalls").val(),"record":$("#record").val()};
        outgoingCall= await device.connect({ params: { To: '+1'+toNumber , callerId: dialFrom, user:agentId, tone: "default", leadId:currentLeadId }});
        //getTimestampForTimezone()
        setCall(outgoingCall);

    }
    const dialPackage=[{makeDial:makeDial,setDialGroupDone:setDialGroupDone, incrementQueue:incrementQueue, myQueue: queue, currentLead:currentLead, dialAmount:dialAmount, device:device}];
    const handlCallAccepted=(type)=>{
        setCallAccepted(type);
    }

    const newEventPackage=[{setNewEvent:setNewEvent, setTime:setTime, setDate:setDate}]
    
    const expiration=(parseInt(currentLead.exclusivity) - parseInt(timestamp));

    const parseWeight = (input) => {
        // Use a regular expression to extract the numeric part
        const parsedWeight = parseInt(input.match(/\d+/), 10);
        return parsedWeight;
    };
    const handleProfileUpdate=(key, value)=>{
        setProfile(prevState => ({
            ...prevState,
            [key]: `${value}`
        }));
    }
    useEffect(() => {
        if(currentLead){
            setProfile({
                borrower_age:currentLead ? currentLead.borrower_age:null,
                state:currentLead ? currentLead.state : null,
                feet: currentLead ? currentLead.feet : null,
                inches: currentLead ? currentLead.inches : null,
                weight: currentLead && currentLead.weight ? parseWeight(currentLead.weight) : null,
                faceAmount: faceAmount,
                term: term,
                productOption:7,
                qualifiers:[]
            })
        }
    }, [currentLead.lead_id]);
    const handleSelectTab=(tab)=>{
        if(tab===4){
            setScripting(true);
            selectTab(4);
            setScriptType('qual')
        }
        else if(tab===0){
            selectTab(4)
            setScripting(true);
            setScriptType('dynamic')
        }
        else{
            selectTab(tab)
        }
    }
    const handleScriptType=(type)=>{
        setScriptType(type)
    }
    const returnToLead=()=>{
        setScripting(false);
        setQualifier(false);
        selectTab(1)
    }
    const handleSetQualifier=()=>{
        setQualifier(false)
    }
    const handleSetScripting=()=>{
        setScripting(true);
        selectTab(4);
        setScriptType('qual')
    }
    const stopSession=()=>{
        openQueue();
        dispatch(setStartSession(false));
    }
    if(queue && updateQueuePos){
        return (
            <div className={` ${open ? 'queueModuleWrapper' : 'queueModuleTab'}`}>         
                {open ? (
                    <div className={`queueWrapper ${expanded ? 'fullScreen' : ''}`}>
                        <div className="quilityCloseWindow oppositeColor" onClick={()=>stopSession()}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                        <div className="innerQueueWrapper">
                            <div className="actualQueue">
                                <div className="queueTabs">
                                    <div className={`queueTitle quilityActive`}>
                                        <div className="titleInner">My Queue</div>
                                    </div>
                                </div>
                                <DialModule callState={callState} setSelectedNumber={setSelectedNumber} expanded={expanded} dialAmount={dialAmount} setMyQueue={setMyQueue} amountSelector={amountSelector} device={device} selectedNumber={selectedNumber} call={call} currentLead={currentLead}  setDialAmount={setDialAmount} openDialAmount={openDialAmount} handlCallAccepted={handlCallAccepted} expandSession={expandSession} />
                                
                                {smartQueue == 'smart' ? (
                                    <LeadsQueue setLeadCard={setLeadCard} formatDate={formatDate} queueExpanded={expanded} holdingQueue={holdingQueue.results}/>
                                ) : (
                                    <LeadsQueue setLeadCard={setLeadCard} formatDate={formatDate} queueExpanded={expanded} holdingQueue={holdingQueue.results}/>
                                )}
                            </div>
                            {expanded ? (
                                <>
                                    <div className="centerQueue">
                                        {scripting && (
                                            <>
                                                <div className="recLink solidQuilityActiveButton" style={{position:'absolute', top:'10px'}} onClick={()=>returnToLead()}>Return</div>
                                                <div className="productTabs" style={{marginTop:'45px'}}>
                                                    <div className={`productTab ${scriptType==='dynamic' && 'selectedTab'}`} onClick={()=>handleScriptType('dynamic')}>Dynamic Script</div>
                                                    <div className={`productTab ${scriptType==='qual' && 'selectedTab'}`} onClick={()=>handleScriptType('qual')}>Qualifier Q's</div>
                                                </div>
                                            </>
                                        )}

                                            <div className="showMe" style={{display: scripting && !qualifier? 'inline-block':'none'}}>
                                          
                                            {scriptType==='dynamic' ? (
                                                <Scripting selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} setResults={setResults} profile={profile} setProfile={setProfile} lead={[currentLead]} handleLeadData={handleLeadData}/>
                                            ) : ( 
                                                <span className="qualifierAdjustment">
                                                    <Qualifier from={'script'} possibleConditions={possibleConditions} setPossibleConditions={setPossibleConditions} setProfile={setProfile} profile={profile} setResults={setResults} handleLeadData={handleLeadData} handleSetQualifier={handleSetQualifier} lead={[currentLead]} agentId={agentId}/>
                                                </span>
                                            )}
                                            </div>
                                            <div className="showMe" style={{display: scripting && !qualifier? 'none':'inline-block'}}>
                                                <LeadCardData handleProfileUpdate={handleProfileUpdate} sessionStarted={sessionStarted} setSessionStarted={setSessionStarted} handleSetScripting={handleSetScripting} qualifier={qualifier} setQualifier={setQualifier} handleSelectTab={handleSelectTab} possibleConditions={possibleConditions} setProfile={setProfile} profile={profile} setPossibleConditions={setPossibleConditions} update={update} setResults={setResults} textingNumbers={textingNumbers} newAppt={newAppt} setNewAppt={setNewAppt} setCallState={setCallState} handleUpdateQueueLead={handleUpdateQueueLead} makeDial={makeDial} selectedNumber={selectedNumber} newEventPackage={newEventPackage} setEvent={setEvent} handlCallAccepted={handlCallAccepted} callAccepted={callAccepted} dialPackage={dialPackage} dialGroupDone={dialGroupDone} lead={currentLead} theCall={call} leadId={currentLead.lead_id} />
                                            </div>
                                        
                                    </div>
                                    <div className="rightQueue">
                                        <RightQueueHeader handleSelectTab={handleSelectTab} selected={selected} selectTab={selectTab}/>
                                        {selected==0 ? (
                                            <QueueScripts handleNewObjection={handleNewObjection} currentLead={currentLead} scriptId={currentScript}/>
                                        ) : selected===1 ? (
                                            <QueueConvo handleLeadData={handleLeadData} expiration={expiration} setLoading={setLoading} currentLead={currentLead} lead={currentLead} textingNumbers={textingNumbers} setConversations={setConversations} conversations={conversations} agentId={agentId} teamId={teamId} currentLeadId={currentLeadId} />
                                        ) :  selected===2 ?(
                                            <Email email={currentLead.email} />
                                        ) : selected===3 ? (
                                            <QueueNotes notes={currentLead.notes} leadId={currentLead.lead_id} agentId={agentId} plan={currentLead.policy_plan}/>
                                        ) : (
                                            <Recommendation selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} handleProfileUpdate={handleProfileUpdate} setFaceAmount={setFaceAmount} faceAmount={faceAmount} term={term} setTerm={setTerm} possibleConditions={possibleConditions} results={results} lead={currentLead} profile={profile} />
                                        )}      
                                        
                                    </div>
                                </>
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                ) : (
                   
                    null
                )}
                {event &&(
                    <EditEvent setUpdate={setUpdate} setEvent={setEvent} calendar={calendar} event={event} />
                )}
                {newEvent &&(
                    <AddEvent setAppointment={setAppointment} calendar={calendar} setNewEvent={setNewEvent} agentTimezone={agentInfo.timezone} date={date} time={time} currentLead={currentLead} />
                )}
                {newObjection &&(
                    <div className="objectionViewWrapper">
                        <div className="objectionInnerWrapper">
                            <div className="objectionTitle">{newObjection.name}</div>
                            <div className="quilityCloseWindow" style={{top: '5px'}}onClick={()=>closeObjection()}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                            <div className="objectionContent" dangerouslySetInnerHTML={{ __html: newObjection.content }}></div>
                        </div>
                    </div>
                )}
                
            </div>
        );
    }
}

export default QueueModule;
