import React, { useState, useEffect } from 'react';
import HomeInfo from './sections/homeInfo';
import ContactInfo from './sections/contactInfo';
import Dial from './handleDial';
import LeadCardTopBar from './sections/leadCardTopBar';
import WorkflowBlock from '../../conversations/workflows';
import { fetchWithDefaults } from '../../../config/fetchDefault';
import Qualifier from '../../qualifier/qualifier';
import AdditionalInfo from './sections/additionalInfo';
import { useSelector, useDispatch } from 'react-redux';
import TagComponent from '../../tags/tagComponent';
import LeadCardTags from '../../leads/tags';
import { removeTagFromLead } from '../../../utilities/tagFunctions';
import { unixTommddhia } from '../../../utilities/dashFunction';
import { updateLeadInfo } from '../../../utilities/leadFunctions';
import LeadCardAppointments from '../../appointment/leadCardAppointments';
import WorkflowInfo from '../../workflow/workflowInfo';
import Icon from '../../../assets/icons/icon';

function LeadCardData({handleSetScripting,handleProfileUpdate,sessionStarted, setSessionStarted, qualifier, setQualifier, setProfile, profile, possibleConditions, setPossibleConditions, setResults, newAppt,calendarUsing, setNewAppt, handlCallAccepted, setCallState, handleUpdateQueueLead, theCall, makeDial, selectedNumber, newEventPackage, callAccepted,dialPackage, lead, setEvent,update}) {
    
    const dispatch=useDispatch();
    const currentLead=[useSelector((state) => state.queue.currentLead)];
    const agentInfo=useSelector((state) => state.agent.userData);
    const queue=useSelector((state) => state.queue.queue);
    const backUp=useSelector((state) => state.queue.originalQueue);
    const [selectedTags, setSelectedTags] = useState([]);
    const [viewAppointments, setViewAppointments] = useState(false);
    const [leadData, setLeadData] = useState([lead]);
    const [openDispo, setOpenDispo] = useState(false);
    //const [qualifier, setQualifier] = useState(false);
    const [flowData, setFlowData] = useState(false);
    
    const keysWithNullValue = [];
    const keysWithNonNullValue = [];
    const handleSetQualifier = (link) => {
        setQualifier(!qualifier);
    };
    function UnixTimestampToDate(unixTimestamp) {
        // Create a new Date object from the Unix timestamp (in milliseconds)
        const date = new Date(unixTimestamp * 1000);
      
        // Get the month and day from the date object
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
      
        // Format the date as "MM/DD"
        const formattedDate = `${month}/${day}`;
      
        return formattedDate;
    }
    const handleLeadData=(field, value)=>{
        let index=0;
        setLeadData(prevData => {
            const newData = [...prevData];
            // Check if index is valid
            if (index >= 0 && index < newData.length) {
              // Update the field value for the object at the specified index
              newData[index] = {
                ...newData[index],
                [field]: value
              };
            }
            return newData;
        });
        handleUpdateQueueLead(field,value,leadData[0].lead_id)
    }
    const keysToIgnore=['email','ben_type','additional_info','return_call','status_history','request_id','qualifier','credit_rating','dnc','custom','policy_plan','amount_contacted','date_entered','date_uploaded','total_emails','last_contacted','team_id','sent_by','expiration','parent_status','assigned_agent','additional_policy','lead_type','county','call_to','call_from','batch','pdf','lead_status','lead_id','agent_id','address','city','state','zip','address2','co_borrower_first', 'co_borrower_last', 'co_borrower_cell', 'co_borrower_home', 'co_borrower_work','borrower_first', 'borrower_last','borrower_cell', 'borrower_home', 'borrower_work','brood','call_begin','call_end','file_link','home_owner','lead_cost','lat','lon','medical_conditions','ninja_status','old_lead_id','old_ninja_id','original_lead','script','scheduled_call','sub_type','system_notes','notes','tags','type_of_lead','value','zoom_scheduled']
    const nullToIgnore=['agent_who_sold','sold_manager','policy_number','apv','face_amount','required_monthly','app_status_date','app_link','app_date','application_type','carrier','policy','app_notes','hobby','motivation','vendor']
    // Iterate through the array and organize keys
    
    function formatNumber(phoneNumber){
        const cleaned = phoneNumber.toString().replace(/\D/g, ''); // Remove all non-digit characters
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber; 
    }
    
    const formatField=(inputString)=>{
        if (inputString.includes('co_borrower_')) {
            // Remove "borrower_" and capitalize the remaining string
            return inputString.replace('co_borrower_', 'Co Lead ').replace(/\b\w/g, (c) => c.toUpperCase());
       
        } else if (inputString.includes('borrower_')) {
        // Remove "co_borrower_" and capitalize the remaining string
            return inputString.replace('borrower_', 'Lead ').replace(/\b\w/g, (c) => c.toUpperCase());
        } else if (inputString.includes('_')) {
        // Capitalize the entire string
            return inputString.replace('_', ' ').replace(/\b\w/g, (c) => c.toUpperCase());
        } else {
        // Capitalize the entire string
            return inputString.replace(/\b\w/g, (c) => c.toUpperCase());
        }
    }
    leadData.forEach((dataObject) => {
        for (const key in dataObject) {
            if (keysToIgnore.includes(key)) {
                continue;
            }
            if (dataObject[key] === null) {
                if (nullToIgnore.includes(key)) {
                    continue;
                }
                keysWithNullValue.push(key);
            } else {
                keysWithNonNullValue.push(key);
            }
        }
    });
    useEffect(() => {
        setLeadData([lead]);
    }, [lead]);
    const openDispositions=()=>{
        setOpenDispo(!openDispo)
    }
    const handleAppointmentView=()=>{
        setViewAppointments(true)
    }

    const viewFlowData= async (id)=>{
        try {
            const response = await fetchWithDefaults(`/flowInfo?lead=${leadData[0].lead_id}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setFlowData(jsonData);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const closeData = ()=>{
        setFlowData(false);
    }
    const stopFlow = async (id)=>{
        try {
            const response = await fetchWithDefaults(`/stopFlow?flow=${id}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setFlowData(false);
            let index=0;
            setLeadData(prevData => {
                const newData = [...prevData];
                // Check if index is valid
                if (index >= 0 && index < newData.length) {
                // Update the field value for the object at the specified index
                newData[index] = {
                    ...newData[index],
                    ['wf_name']: null,
                    ['wf_id']: null
                };
                }
                return newData;
            });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const removeTag= async (tagIdToRemove)=>{
        const tagRemoved=await removeTagFromLead(tagIdToRemove, agentInfo.agentId, leadData[0].lead_id);
        if(tagRemoved){
            const updatedTags = selectedTags.filter(tag => tag.id !== tagIdToRemove);
            // Update the state with the filtered array
            setSelectedTags(updatedTags);
        }
    }
    const addTag= (tag) => {
        //addTagToLead(setSelectedTags, selectedTags, tag);

        let tags;
        if(selectedTags.length > 0){
            tags=[selectedTags[0],tag];
        }
        else{
            tags=[tag];
        }
        
        setSelectedTags([...selectedTags, tag]);
        const formattedString = tags
        .map(item => `${item.id}*${item.name}`)
        .join(',');

        updateLeadInfo(queue,'tag_ids',formattedString,leadData[0].lead_id, dispatch, 'queue')
        updateLeadInfo(backUp,'tag_ids',formattedString,leadData[0].lead_id, dispatch, 'backup');
    }
   
    return (
        <div className="leadCardMainWrapper">
            
            {currentLead && currentLead.map((item, index) => (
                <span key={`leadInfo${index}`}>
                    <div className="leadCardHeader" >
                        <span className="leadName">{item.borrower_first ? item.borrower_first.replace(/\\/g, '') : 'unknown'} {item.borrower_last ? item.borrower_last.replace(/\\/g, '') : 'unknown'}</span> 
                         <LeadCardTags tagIds={item.tag_ids} leadId={item.lead_id}/>
                        {selectedTags.length > 0 && (
                            selectedTags.map((tag, index) => (
                                <span className="tagWrapper" key={`taggedLead${tag.id}`}>
                                    {tag.name}
                                    <span className="removeTag solidQuilityDestructiveButton" onClick={()=>removeTag(tag.id)}>x</span>
                                </span>
                            ))
                        )}
                        <div style={{position: 'absolute',right: '0',top: '0'}}><TagComponent selectedTags={selectedTags} setSelectedTags={setSelectedTags} leadId={item.lead_id}/></div>
                        
                        {currentLead[0].appointments && (
                            {/*<LeadCardAppointments appointments={lead.appointments} lead={lead.lead_id}/>*/},
                            <div className="tagIcon" style={{position: 'absolute',right: '-12px',marginTop: '-22px'}} onClick={()=>handleAppointmentView()}>
                                <span className="fontello" style={{marginRight: '5px',lineHeight: '0px'}}><Icon name={'calendar-date-selection-q'} size={20} height='24' className="" adjust="fontello" /></span>
                            </div>
                        )}
                        { viewAppointments &&(
                            <LeadCardAppointments setViewAppointments={setViewAppointments} appointments={currentLead[0].appointments} lead={currentLead[0].lead_id}/>
                        )}
                    </div>
                        {item.status_name && (
                            <span className="statusName">{item.status_name}</span>
                        )}
                         {item.wf_name && (
                            <WorkflowInfo type='workflow' title={item.wf_name} workflowId={item.wf_id} hopperId={item.wf_hopper_id} />
                        )}
                        {item.convoFlowName && (
                            <WorkflowInfo type='conversation' title={item.convoFlowName} workflowId={item.pathway} hopperId={item.wf_hopper_id} />
                        )}
                    <div className="leadCardAddressWrapper">
                        <div className="addressHolder">
                            <span className="addressSlot">{item.address+',' ?? 'Click To Add'}</span>
                            <span className="addressSlot">{item.city+',' ?? 'Click To Add'}</span>
                            <span className="addressSlot">{item.state ?? 'Click To Add'}</span>
                            <span className="addressSlot">{item.zip ?? 'Click To Add'}</span>
                        </div>
                        <div className="badgeHolder">
                            <span className="queueInnerBadgeHolder">
                                <span className="badgeButton">Lead Type <span style={{fontWeight:'normal'}}>{item.lead_type}</span></span>
                                {item.wf_name !== null && (
                                    <span className="badgeButton" onClick={()=>viewFlowData(item.wf_hopper_id)}>Flow <span style={{fontWeight:'normal'}}>{item.wf_name}</span></span>
                                )}
                            </span>
                        </div>
                        {item.exclusivity && (
                            <div className="exclusivity" style={{marginTop:'unset'}}>Exclusive until - {unixTommddhia(item.exclusivity)}</div>
                        )}
                    </div>
                    
                    <LeadCardTopBar handleSetScripting={handleSetScripting} update={update} setQualifier={handleSetQualifier} qualifier={qualifier} calendarUsing={calendarUsing} newAppt={newAppt} setNewAppt={setNewAppt} newEventPackage={newEventPackage} setEvent={setEvent} leadData={leadData[0]} />
                    <div className="leadCardContent" style={{overflowY: 'auto'}}>
                        <div className="quilityParentBlockContent" style={{height: `${qualifier ? 'calc(100%  - 190px)': '72%'}`,overflow: 'auto', paddingTop:`${qualifier ? '0px': '20px'}`}}>
                            {qualifier ? (
                                <Qualifier handleProfileUpdate={handleProfileUpdate} possibleConditions={possibleConditions} setPossibleConditions={setPossibleConditions} setResults={setResults} setProfile={setProfile} profile={profile} handleLeadData={handleLeadData} handleSetQualifier={handleSetQualifier} lead={leadData} agentId={agentInfo.agentId}/>
                            ) : (
                                <>
                                    <div className="leadType">{item.type_of_lead !== null ? `Lead Type: ${item.type_of_lead}` : null}</div>
                                    <ContactInfo selectedNumber={selectedNumber} handleLeadData={handleLeadData} makeDial={makeDial} agentId={agentInfo.agentId} formatField={formatField} formatNumber={formatNumber} leadData={leadData}  />
                                    {leadData[0].custom_fields && (
                                        <AdditionalInfo  handleLeadData={handleLeadData} agentId={agentInfo.agentId} formatField={formatField} formatNumber={formatNumber} leadData={leadData} />
                                    )}
                                    <HomeInfo handleLeadData={handleLeadData} agentId={agentInfo.agentId} formatField={formatField} formatNumber={formatNumber} leadData={leadData} />
                                </>
                            )}
                        </div>
                        {dialPackage && dialPackage[0].dialAmount && (
                            <Dial sessionStarted={sessionStarted} setSessionStarted={setSessionStarted} handleLeadData={handleLeadData} from="session" theCall={theCall} setCallState={setCallState} selectedNumber={selectedNumber} handlCallAccepted={handlCallAccepted} callAccepted={callAccepted} makeDial={dialPackage[0].makeDial} setDialGroupDone={dialPackage[0].setDialGroupDone} incrementQueue={dialPackage[0].incrementQueue} currentLead={dialPackage[0].currentLead} dialAmount={dialPackage[0].dialAmount} device={dialPackage[0].device}/>
                        )}
                    </div>
                </span>
            ))} 
            
            {flowData && (
                <div className="flowDataWrapper">
                    <div className="flowDataContent">
                        <div className="closeWindow" onClick={()=>closeData()}>X</div>
                        {flowData.map((item, index) => (
                            <>
                                <div className="title">Workflow Info</div>
                                <div className="flowDataInfoWrapper">
                                    <div className="flowDataInfoTitle">
                                        Workflow Name:
                                    </div>
                                    <div className="flowDataInfoInfo">
                                        {item.name}
                                    </div>
                                </div>
                                <div className="flowDataInfoWrapper">
                                    <div className="flowDataInfoTitle">
                                        Date Started:
                                    </div>
                                    <div className="flowDataInfoInfo">
                                        {UnixTimestampToDate(item.start)}
                                    </div>
                                </div>
                                <div className="flowDataInfoWrapper">
                                    <div className="flowDataInfoTitle">
                                        Workflow Length:
                                    </div>
                                    <div className="flowDataInfoInfo">
                                        {item.totalResult} Actions
                                    </div>
                                </div>
                                <div className="flowDataInfoWrapper">
                                    <div className="flowDataInfoTitle">
                                        Actions Remaining:
                                    </div>
                                    <div className="flowDataInfoInfo">
                                        {item.activeCount}
                                    </div>
                                </div>
                                <div className="buttonWrapper">
                                    <div className="accentButton" style={{margin:'auto', marginTop:'25px'}} onClick={()=>stopFlow(item.theId)}>Stop Workflow</div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            )}
            
        </div>
    );
}

export default LeadCardData;