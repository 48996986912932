import React, { useState, useEffect, useRef } from 'react';
import '../../../../css/qualifier.css';



import ReccomendationBlock from '../../../reccomendation/reccomendationBlock';
import ExtraInfo from '../../../reccomendation/extraInfo';

function Recommendation({selectedProducts, setSelectedProducts, handleProfileUpdate,setFaceAmount,faceAmount,term,setTerm,results,lead, profile, possibleConditions}) {
    const [extraInfo, setExtraInfo] = useState(false);
    const [selectedTab, setSelectedTab] = useState('term');
    const [recWithQuote, setRecWithQuote] = useState(false);
    


    const handleSelectedTab=(type)=>{
        let typeId;
        if(type==='term'){
            typeId='7';
        }
        else{
            typeId='6'
        }
        handleProfileUpdate('productOption',typeId)
        setSelectedTab(type)
    }



    const handleSelectedProducts=(faceAmount, product)=>{
        setSelectedProducts((prevItems) => {
            const exists = prevItems.some(item => item.product.tempId === product.tempId);

            if (exists) {
              // Remove the item if it exists
              return prevItems.filter(item => item.product.tempId !== product.tempId);
            } else {
              // Add the item if it doesn't exist
              return [...prevItems, { face: faceAmount, product }];
            }
        });
    }

    const closePopup=()=>{
        setExtraInfo(false);
    }
    const handleTermChange=(event)=>{
        setTerm(event.target.value)
        handleProfileUpdate('term',event.target.value);
    }
    const handleFaceChange=(value)=>{
        setFaceAmount(value)
        //handleProfileUpdate('faceAmount',value);
    }
    const handleSetFaceAmount=(value)=>{
        handleProfileUpdate('faceAmount',value);
    }
    
    useEffect(() => {

        setRecWithQuote()
    }, []);

    useEffect(() => {
        if(selectedTab==='term'){
            if(faceAmount !=='100000'){
                handleFaceChange('100000')
                handleSetFaceAmount('100000')
            }
        }
        else if(selectedTab==='whole'){
            if(faceAmount !=='25000'){
                handleFaceChange('25000')
                handleSetFaceAmount('25000')
            }
        }
    }, [selectedTab]);

    return (
        <div className='recWrapper'>
            <div className="productTabs">
                <div className={`productTab ${selectedTab==='term' ? 'selectedTab':''}`} onClick={()=>handleSelectedTab('term')}>Term Products</div>
                <div className={`productTab ${selectedTab==='whole' ? 'selectedTab':''}`} onClick={()=>handleSelectedTab('whole')}>Whole Life</div>
            </div>
            <div className="recOptions">

                <div className="recInputHolder">
                    <div className="recInputTitle">
                        Face Amount
                    </div>
                    <input type="text" className="recInput" onChange={(e)=>handleFaceChange(e.target.value)}value={faceAmount} onBlur={(e)=>handleSetFaceAmount(e.target.value)}/>
                </div>
                {selectedTab==='term' && (
                    <div className="recInputHolder">
                        <div className="recInputTitle">
                            Term Length
                        </div>
                        <select className="recInputSelect" onChange={handleTermChange} value={term}>
                            <option value="10">10 Year</option>
                            <option value="15">15 Year</option>
                            <option value="20">20 Year</option>
                            <option value="25">25 Year</option>
                            <option value="30">30 Year</option>
                            <option value="35">35 Year</option>
                            <option value="40">40 Year</option>
                        </select>
                        {/*<input type="text" className="recInput"/>*/}
                    </div>
                )}
            </div>
            <div className="recResults">
                {results && results.map((result, index) => (
                    <ReccomendationBlock key={`recc2${index}`} faceAmount={faceAmount} possibleConditions={possibleConditions} selectedProducts={selectedProducts} lead={lead} reccomendation={result} handleSelectedProducts={handleSelectedProducts} setExtraInfo={setExtraInfo}/>
                ))}
            </div>
            {extraInfo && (
                <ExtraInfo extraInfo={extraInfo} closePopup={closePopup} />
            )}
        </div>
    );

}

export default Recommendation;

