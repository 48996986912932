import React, { useEffect } from 'react';
import '../../css/qualifier.css';
import QualifierBlock from './sections/qualifierBlock';


function Qualifier({handleProfileUpdate, lead, handleLeadData, handleSetQualifier, setResults, profile, setProfile, possibleConditions, setPossibleConditions, from}) {
   
    const leadData=[
        {name:'First',column:'borrower_first', type:'input'},
        {name:'Last',column:'borrower_last', type:'input'},
        {name:'Sex',column:'borrower_sex', type:'drop', options:[{title:'Select Sex',value:''},{title:'Male',value:'Male'},{title:'Female',value:'Female'}]},
        //{name:'DOB',column:'borrower_dob', type:'input'},
        {name:'Age',column:'borrower_age', type:'input'},
        {name:"Feet (height)", column:'feet', type:'input'},
        {name:"Inches (height)", column:'inches', type:'input'},
        {name:'Weight',column:'weight', type:'input'},
        {name:'Has Insurance',column:'borrower_is_covered', type:'input'},
        {name:'Current Coverage',column:'borrower_coverage', type:'input'},
        {name:'Occupation', column:'borrower_occupation', type:'input'},
        {name:'Primary Concern',column:'concerns', type:'input'},
        {name:'Tobacco Use',column:'tobacco', type:'drop', options:[{title:'Select Tobacco Use',value:''},{title:'Non-Tobacco',value:'no'},{title:'Tobacco',value:'yes'}]},
    ]

    const coLeadData=[
        {name:'First',column:'co_borrower_first', type:'input'},
        {name:'Last',column:'co_borrower_last', type:'input'},
        {name:'DOB',column:'co_borrower_dob', type:'input'},
        {name:'Age',column:'co_borrower_age', type:'input'},
        {name:'Height',column:'co_borrower_height', type:'input'},
        {name:'Weight',column:'co_borrower_weight', type:'input'},
        {name:'Has Insurance',column:'co_borrower_is_covered', type:'input'},
        {name:'Current Coverage',column:'co_borrower_coverage', type:'input'},
        {name:'Occupation', column:'co_borrower_occupation', type:'input'},
        {name:'Tobacco Use',column:'co_borrower_tobacco', type:'input'}
    ]
    const mortgage=[
        {name:'Mortgage Value',column:'mortgage', type:'input'},
        {name:'Home Value',column:'home_value', type:'input'},
        {name:'Loan Type',column:'loan_type', type:'input'},
        {name:'Loan Term',column:'term_length', type:'input'},
        {name:'Home Payment',column:'payment', type:'input'}
    ]

    useEffect(() => {

    },[])
  
    return (
        <div className="qualifierData" style={{margin: 'unset', position:'relative'}}>
            {!from && (
                <>
                    <div className='returnToLeadCard solidQuilityActiveButton' onClick={()=>handleSetQualifier()}>Return To Lead</div>
                    <div className="title">Qualifier</div>
                </>
            )}
            <QualifierBlock possibleConditions={possibleConditions} setPossibleConditions={setPossibleConditions} profile={profile} setProfile={setProfile} setResults={setResults} lead={lead} setLead={handleLeadData} title="Lead Information" startOpen={true} leadMainData={leadData}/>
            <QualifierBlock possibleConditions={possibleConditions} setPossibleConditions={setPossibleConditions} profile={profile} setProfile={setProfile} setResults={setResults} lead={lead} setLead={handleLeadData} title="Mortgage Info" startOpen={true} leadMainData={mortgage}/>
            <QualifierBlock possibleConditions={possibleConditions} setPossibleConditions={setPossibleConditions} profile={profile} setProfile={setProfile} setResults={setResults} lead={lead} setLead={handleLeadData} title="Co Lead Information" startOpen={false} leadMainData={coLeadData}/>
        </div>
    );
}

export default Qualifier;