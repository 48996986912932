import React, { useState, useRef, useEffect } from "react";
import EngagedList from "./engaged";
import Icon from "../../../assets/icons/icon";

function FilterHolderBlock({filters,getMyFilter,title, open}) {
    //const filters=useSelector((state) => state.filters.filters);
    const additionalFilters=[
        {name:'Monthly Dials', type:'Engaged', params:'list', search:"1m"}
    ];
    const [isOpen, setIsOpen] = useState(true);
    
    const innerWrapperRef = useRef(null);
    
    const getHeight = () => {
        if(innerWrapperRef.current && innerWrapperRef.current.clientHeight===0){
            return 0
        }
        if (innerWrapperRef.current) {
            return innerWrapperRef.current.clientHeight;
        }
        return 0;
    }; 
    const handleOptionClick = (option) => {
        setIsOpen(!isOpen);
    };
    
    useEffect( () => {
        setIsOpen(open);
    },[])

    return (
        <span className="quilityNestedBlockWrapper" style={{ height: isOpen && getHeight() !==0 ? getHeight()+90 : '45px',maxHeight:'335px'}}>
            <div className="quilityNestedBlockHeader" onClick={()=>handleOptionClick()}>
                <div className="quilityParentBlockTitle">{title}</div>
                <span className={`quilityBlockIcon ${isOpen ? 'rotate':''}`} style={{marginTop: '-5px', transition:'all .5s'}}><span className="fontello"><Icon name={'q-up'} size={20} className="closeIcon" adjust="fontello" /></span></span>
            </div>
            <div className="quilityNestedBlockContent"  ref={innerWrapperRef} style={{maxHeight:'300px',overflow: 'auto',width: 'calc(100% - 40px)'}}>
            
                {additionalFilters && additionalFilters.map((filter, index) => (
                    <EngagedList getMyFilter={getMyFilter} filter={filter} index={index} className='filterAdjust' key={`filterKey${index}`}/> 
                ))}
                {filters && filters.map((filter, index) => (
                    <EngagedList getMyFilter={getMyFilter} filter={filter} index={index} className='filterAdjust' key={`filterKey${index}`}/> 
                ))}
            </div>
        </span>
    );
}

export default FilterHolderBlock;
