import React, { useState, useEffect } from "react";
import FilterButton from "../../elements/filterButton";
import RemoveableOption from "../../elements/removeableOption";
import { fetchWithDefaults } from '../../../config/fetchDefault';
import { useSelector, useDispatch } from 'react-redux';
import { addFilter } from '../../../features/filterSlice';
import { setQueue } from '../../../features/queueSlice';
import {  setActiveFilter } from '../../../utilities/appSetup';
import { toggleSelectedOption,toggleSearch , clearSelectedOption} from "../../../features/leadFilterSlice";

function FilteringOptions({ activeFilterName, queueExpanded, filterOpen, jumpIntoQueue}) {
    console.log(activeFilterName);
    const dispatch = useDispatch();
    const workflows=useSelector((state) => state.workflows.workflows);
    const queue=useSelector((state) => state.queue.queue);
    const agentInfo=useSelector((state) => state.agent.userData);
    const [nameModule, setNameModule] = useState(false);
    const selectedOptions = useSelector(state => state.filterSearch.selectedOptions);
    const [filterName, setFilterName] = useState('');
    const [typeName, setTypeName] = useState(false);
    let filters=[];
    const wholeQueue=useSelector((state) => state.queue.wholeQueue);
    const states=useSelector((state) => state.states.states);
    const status=useSelector((state) => state.status.status);
    const leadType=useSelector((state) => state.leadType.leadType);
    const tags=useSelector((state) => state.tags.tags);
    const filterSearch = useSelector(state => state.filterSearch.search);
    const [dropdownOpen, setDropdownOpen] = useState(
        {
            filteringOptions0:false,
            filteringOptions1:false,
            filteringOptions2:false,
            filteringOptions3:false
        }
    );
     
    if(agentInfo !== ''){
        filters=[
            {name:'Regions', search:'state',values:[states]},
            //{name:'Parent Status',search:'parent_status',values:[[{name:'New',value:'new'},{name:'Active',value:'active'},{name:'Client',value:'client'},{name:'Dead',value:'dead'}]]},
            {name:'Lead Status',search:'lead_status', values:[status]},
            {name:'Lead Type', search:'lead_type', values:[leadType]},
            {name:'Lead Tag',search:'tags', values:[tags]},
            //{name:'Workflow',search:'workflow',values:[workflows]}
        ];
    }
    const filterObjects = (criteria) => {
        return queue.filter(item => {
            return Object.entries(criteria).every(([key, values]) => {
                if (key === "tags") {
                    return values.some(tag => item.tag_ids && item.tag_ids.includes(tag));
                } else {
                    // Normalize keys and values to lowercase for case-insensitive comparison
                    const itemValue = item[key]?.toLowerCase();
                    const normalizedValues = values.map(value => value.toLowerCase());
                    
                    // Check if itemValue exists in the criteria's values array
                    return normalizedValues.includes(itemValue);
                }
            });
        });
    };
    const handleOptionClick = (option,search) => {
        dispatch(toggleSearch({search,option}));
        dispatch(toggleSelectedOption({option:search,search:option}));
    };
    const handleDisplayClick = (option,search, name) => {
        dispatch(toggleSearch({search,option}));
        dispatch(toggleSelectedOption({option:search,search:option,name:name}));
    };
    
    useEffect( () => {
        if(selectedOptions.length > 0){
            setNameModule(true);
        }
        const thing=filterObjects(filterSearch)
        dispatch(setQueue(thing))
    },[filterSearch])
    const saveFilterName=async ()=>{
        if(filterName !== ''){
            try {
               
                const response = await fetchWithDefaults(
                    `/filters/saveFilter`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json", 
                        },
                        body: JSON.stringify({ 
                            searchData: selectedOptions,
                            agent:agentInfo.agentId,
                            filterName:filterName,
                            teamId:agentInfo.teamId,
                        })
                    }
                );
                
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const jsonData = await response.json();
                if(jsonData.status !==false){
                    dispatch(addFilter(jsonData));
                    setTypeName(false);
                    setFilterName('')
                    setNameModule(false);
                }
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }
    const handleInputChange = (event) => {
        setFilterName(event.target.value);
    };
    const handleCancelName=()=>{
        setTypeName(false);
        setFilterName('')
    }
    const clearFilters=()=>{
        setActiveFilter('Last 90 Days', dispatch);
        dispatch(setQueue(wholeQueue));
        dispatch(clearSelectedOption([]));
        setNameModule(false);
    }
    return (
        <div className={`mainFilterBlockWrapper expandFilters ${filterOpen && queueExpanded ? 'filterQueueOpen' : filterOpen? 'expandFilters' : ''}`} style={{height: queueExpanded === undefined ? '' : queueExpanded && filterOpen ? '' : '0px', width: queueExpanded && filterOpen ? '' : queueExpanded ? '0px' : ''}}>
            <div style={{textAlign: 'left'}}>
                <RemoveableOption />
            </div>
            <div className="bulkTitle">
                Filter Options 
                
                {(selectedOptions && selectedOptions.length > 0) || activeFilterName !== 'Last 90 Days' && (
                    <div className="clearFilters" onClick={()=>clearFilters()}>Clear Filters</div>
                )}
                {nameModule && (
                    <div className="nameFilterWrapper solidQuilityActiveButton">
                        {typeName && (
                            <div className="filterInputWrapper">
                                <input 
                                    type="text" 
                                    className="qualifierFieldInput" 
                                    style={{width: 'calc(100% - 20px)',height: '15px'}}
                                    value={filterName} 
                                    onChange={handleInputChange} 
                                />
                            </div>
                        )}
                        {selectedOptions.length > 0 && (
                            <div className="nameFilterTitle" onClick={(e)=>{setTypeName(true);e.stopPropagation();}}><span className="saveListName saveFilterButtonOption" onClick={()=>saveFilterName()}>Save</span>{!typeName ? 'Filter' : <span className="cancelSave saveFilterButtonOption" onClick={(e)=>{handleCancelName();e.stopPropagation();}}>Cancel</span>}</div>
                        )}
                    </div>
                )}
                
                {queue && (
                    !typeName && (
                        <span className="optionButtonsHolder">
                            {/*<SmartSort data={queue}/>*/}
                            {queue.length > 0 && (
                                <div className="jumpIntoButton solidQuilityActiveButton" onClick={()=>jumpIntoQueue()}>Jump Into Queue</div>
                            )}
                            
                        </span>
                    )
                )}
            </div>
            
            <div className="filterOptionsBarWrapper">
                {filters.length > 0 && filters.map((filter, index) => (
                    activeFilterName==='Clients' ? (
                        <FilterButton activeFilterName={activeFilterName} dropdownOpen={dropdownOpen} setDropdownOpen={setDropdownOpen} handleDisplayClick={handleDisplayClick} id={`filteringOptions${index}`} handleOptionClick={handleOptionClick} title={filter.name || filter.lead_type} search={filter.search} values={filter} type="" key={`filteringOptions${index}`}/>     
                    ) : (
                        <FilterButton activeFilterName={activeFilterName} dropdownOpen={dropdownOpen} setDropdownOpen={setDropdownOpen} handleDisplayClick={handleDisplayClick} id={`filteringOptions${index}`} handleOptionClick={handleOptionClick} title={filter.name || filter.lead_type} search={filter.search} values={filter} type="" key={`filteringOptions${index}`}/>
                    )
                    
                ))}
            </div>
        </div>
    );
}

export default FilteringOptions;
// /<RemoveableOption search={selected.search} name={selected.name || selected.states || selected.lead_type} remove={remove} item={selected} key={`removeableOption`+index} index={index}/>