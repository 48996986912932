import React from 'react';
import { fetchWithDefaults } from '../../../config/fetchDefault';

function QualifierSection({lead, data, setLead, agentId, handleSetProfile}) {
    const handleChange = (event, column) => {
        const { value } = event.target;
        setLead(column, value);
    };
    const parseWeight = (input) => {
        // Use a regular expression to extract the numeric part
        const parsedWeight = parseInt(input.match(/\d+/), 10);
        return parsedWeight;
    };
    const handleBlur = async (event, column, leadId) => {
        const { value } = event.target;
        let newValue=value;
        if(column==='weight'){
            newValue=parseWeight(value);
        }
        handleSetProfile(column, newValue);
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({
                    agent:agentId,
                    lead:leadId,
                    column:column,
                    value: value
                })
            };

            const response = await fetchWithDefaults('/updateLead', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (
        <div className="qualifierSection">
            {data && data.map((item, index) => (
                <div className="qualifierFieldWrapper" key={`qualifierField${index}`}>
                    <div className="qualifierFieldTitle">{item.name}</div>
                    {item.type==='drop' ? (
                        <select className="outlineButton filterButton" onChange={(event)=>[handleChange(event, item.column),handleBlur(event, item.column, lead[0].lead_id)]} value={lead[0][item.column]}>
                            {item.options && item.options.map((option, index) => ( 
                                <option value={option.value}>{option.title}</option>
                            ))}
                            
                        </select>
                    ) : (
                        <input 
                            type="text" 
                            className="qualifierFieldInput" 
                            value={lead[0][item.column]}
                            onChange={(event) => handleChange(event, item.column)}
                            onBlur={(event) => handleBlur(event, item.column, lead[0].lead_id)}
                        />
                    )}
                </div>
            ))}
        </div>
    );
}

export default QualifierSection;
