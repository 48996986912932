import moment from 'moment-timezone';

function UnixTimestampToDate(unixTimestamp) {
    // Create a new Date object from the Unix timestamp (in milliseconds)
    const date = new Date(unixTimestamp * 1000);
  
    // Get the month and day from the date object
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
  
    // Format the date as "MM/DD"
    const formattedDate = `${month}/${day}`;
  
    return formattedDate;
}
const isWithin24Hours = (timestamp) => {
    if (!timestamp) return false; // Check if the timestamp is null or undefined

    const givenTime = new Date(timestamp); // Convert the timestamp to a Date object
    const currentTime = new Date(); // Get the current time

    // Calculate the difference in time (milliseconds)
    const timeDifference = currentTime - givenTime;

    // Convert the time difference to hours and check if it's within 24 hours
    const hoursDifference = timeDifference / (1000 * 60 * 60);

    return hoursDifference <= 24;
};
const getTimestampForTimezone = (timezone) => {
    // Get the current time in the specified timezone using moment-timezone
    const now = moment.tz(timezone);
  
    // Format the timestamp as 'YYYY-MM-DD HH:mm:ss'
    return now.format('YYYY-MM-DD HH:mm:ss');
};
export {UnixTimestampToDate,isWithin24Hours,getTimestampForTimezone};